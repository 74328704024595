/* Existing styles for modal and table */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 12px;
  max-width: 800px; /* Adjust width as needed */
  width: 90%;
  max-height: 80vh; /* Limit height to allow scrolling */
  overflow-y: auto; /* Add scrolling */
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff6b6b;
  border: none;
  border-radius: 50%;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
}

.modal-close:hover {
  background: #ff3b3b;
}

/* Styles for sortable table headers */
table th {
  cursor: pointer;
  position: relative;
  padding: 10px;
}

table th:hover {
  background-color: #f1f1f1;
}

table th.sorted-asc::after,
table th.sorted-desc::after {
  content: '';
  position: absolute;
  right: 10px;
  border: 5px solid transparent;
}

table th.sorted-asc::after {
  border-bottom-color: #333;
  top: 50%;
  transform: translateY(-50%);
}

table th.sorted-desc::after {
  border-top-color: #333;
  top: 50%;
  transform: translateY(-50%);
}

/* Styles for unopened buttons */
.unopened-button {
  background-color: #ff6b6b; /* Blue background color */
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.unopened-button:hover {
  background-color: #ff3b3b; /* Darker blue on hover */
  transform: scale(1.05); /* Slightly larger on hover */
}

.unopened-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(1, 0, 2, 0.5); /* Custom focus outline */
}
