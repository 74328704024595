body {
  background-color: #f7be00 !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* .schedule-container {
  position: relative;
  z-index: 1; /* Lower than the modal */


.schedule-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #f7be00;

}

.schedule-form {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group input,
.form-group select {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.schedule-button {
  text-decoration: none;
  padding: 15px 20px;
  font-size: 18px;
  background-color: #006b68;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: 600;
  margin-top: 20px;
  width: 100%;
}

.schedule-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* Style for the table */
.scheduled-jobs-table {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.scheduled-jobs-table th, .scheduled-jobs-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.scheduled-jobs-table th {
  background-color: #006b68;
  color: white;
  font-weight: bold;
}

.scheduled-jobs-table tr:hover {
  background-color: #f1f1f1;
}

.delete-button {
  padding: 8px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.delete-button:hover {
  background-color: #ff0000;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .schedule-container {
    padding: 10px;
  }

  .schedule-form {
    padding: 20px;
  }

  .form-group input,
  .form-group select {
    padding: 8px;
  }

  .schedule-button {
    padding: 8px;
    font-size: 14px;
  }

  .scheduled-jobs-table th, .scheduled-jobs-table td {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .schedule-container {
    padding: 5px;
  }

  .schedule-form {
    padding: 15px;
  }

  .form-group input,
  .form-group select {
    padding: 6px;
  }

  .schedule-button {
    padding: 6px;
    font-size: 12px;
  }

  .scheduled-jobs-table th, .scheduled-jobs-table td {
    padding: 8px;
    font-size: 12px;
  }

  .delete-button {
    padding: 6px 10px;
    font-size: 12px;
  }
}
