body {
  background-color: #f7be00 !important;
  font-family: 'Open Sans', Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
  color: #f7be00;
  background-size: cover;
  background-position: center;
  position: relative;
  background-blend-mode: overlay;
  background-attachment: fixed;
  animation: zoomBackground 20s infinite alternate;
}

@keyframes zoomBackground {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 110%;
  }
}

.home-overlay {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
  padding: 20px;
  border-radius: 10px;
  /* backdrop-filter: blur(5px); */
}

.header1 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.logout-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #006b68;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #e60000;
}

.logo-image {
  width: 350px; /* Adjust the size as needed */
  margin-bottom: 20px;
}

.text-container {
  margin-bottom: 20px;
}

h1 {
  font-size: 32px;
  margin: 10px 0;
  font-weight: 700;
  color: rgb(3, 3, 3);
}

p {
  font-size: 20px;
  margin: 10px 0 20px;
  font-weight: 400;
  color: rgb(3, 3, 3);
}

.button-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}

.home-button {
  text-decoration: none;
  padding: 15px 20px;
  font-size: 18px;
  background-color: #006b68;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: 600;
}

.home-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

@media (min-width: 600px) {
  .button-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 800px;
  }

  h1 {
    font-size: 36px;
    color: rgb(3, 3, 3);
  }

  p {
    font-size: 22px;
    color: rgb(3, 3, 3);
  }
}
