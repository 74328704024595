body {
  background-color: #f7be00;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px; /* Add padding to avoid content touching the edges on small screens */
  background-color: #F9F9F9;
}

.login-form {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.login-logo {
  width: 300px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-button {
  text-decoration: none;
  padding: 15px 20px;
  font-size: 18px;
  background-color: #006b68;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: 600;
}

.login-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.error-message {
  color: red;
  margin-bottom: 15px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .login-container {
    padding: 10px;
  }

  .login-form {
    padding: 20px;
  }

  .login-logo {
    width: 120px;
  }

  .form-group input {
    width: calc(100% - 16px);
    padding: 8px;
  }

  .login-button {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .login-container {
    padding: 5px;
  }

  .login-form {
    padding: 15px;
  }

  .login-logo {
    width: 100px;
  }

  .form-group input {
    width: calc(100% - 12px);
    padding: 6px;
  }

  .login-button {
    padding: 6px;
    font-size: 12px;
  }
}
